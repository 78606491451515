<template>
  <div>
    <v-card outlined class="mb-5 pa-5" v-if="sedirector_apps.sedirector_app_status==='8' || sedirector_apps.sedirector_app_pvc_approve_ipa==='notice'">
      <div class="text-center">
        <v-timeline
          :dense="$vuetify.breakpoint.smAndDown"
          v-if="checksedirector_app_idrefs === 'true'"
        >
          <v-timeline-item color="red lighten-2" fill-dot left>
            <v-card>
              <v-card-title class="red lighten-2">
                <div class="white--text font-weight-light">
                  <v-icon dark size="20">
                    mdi-magnify
                  </v-icon>
                  ไฟล์แนบของผู้สมัคร
                </div>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th style="width:20%" class="text-center">
                            รายการ
                          </th>

                          <th style="width:50%" class="text-center">
                            ไฟล์แนบ
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="pa-2 text-center">
                            ใบสมัคร และเอกสารประกอบการสมัคร
                          </td>
                          <td class="pa-2">
                            <div
                              class="text-center"
                              v-if="sedirector_apps.sedirector_app_file"
                            >
                              <v-btn
                                rounded
                                color="info"
                                @click="
                                  pdfHrvecRegisDir(
                                    sedirector_apps.sedirector_app_file
                                  )
                                "
                                fab outlined small
                                ><v-icon>mdi-file-document</v-icon>
                                </v-btn
                              >
                            </div>
                        
                          </td>
                        </tr>
                        <tr v-if="sedirector_apps.sedirector_app_ptype==='1'">
                          <td class="pa-2 text-center">
                            เอกสารภาค ก
                          </td>

                          <td class="pa-2">
                            <div
                              class="text-center"
                              v-if="sedirector_apps.sedirector_app_file_A"
                            >
                              <v-btn
                                rounded
                                color="info"
                                @click="
                                  pdfHrvecRegisDir(
                                    sedirector_apps.sedirector_app_file_A
                                  )
                                "
                                fab outlined small
                                ><v-icon>mdi-file-document</v-icon>
                                </v-btn
                              >
                            </div>
                    
                          </td>
                        </tr>

                        <tr>
                          <td class="pa-2 text-center">
                            เอกสารภาค ข
                          </td>

                          <td class="pa-2">
                            <div
                              class="text-center"
                              v-if="sedirector_apps.sedirector_app_file_B"
                            >
                              <v-btn
                                rounded
                                color="info"
                                @click="
                                  pdfHrvecRegisDir(
                                    sedirector_apps.sedirector_app_file_B
                                  )
                                "
                                fab outlined small
                                ><v-icon>mdi-file-document</v-icon>
                                </v-btn
                              >
                            </div>
                   
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>

          <v-timeline-item color="red lighten-2" fill-dot right>
            <v-card>
              <v-card-title class="red lighten-2">
                <div class="white--text font-weight-light">
                  <v-icon dark size="20">
                    mdi-magnify
                  </v-icon>
                  ประกาศรายชื่อผู้มีสิทธิเข้ารับการคัดเลือก ประเมิน ภาค ก
                </div>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col cols="12" md="10">
                    <h3
                      v-if="
                        sedirector_apps.sedirector_app_pvc_approve === 'pass' &&
                          sedirector_apps.sedirector_app_pvc_approve_ipa ===
                            'notice'
                      "
                      class="success--text"
                    >
                      ผ่านคุณสมบัติผู้สมัคร
                    </h3>
                    <h3
                      v-else-if="
                        sedirector_apps.sedirector_app_pvc_approve === 'fail' &&
                          sedirector_apps.sedirector_app_pvc_approve_ipa ===
                            'notice'
                      "
                      class="red--text"
                    >
                      ไม่ผ่านคุณสมบัติ
                    </h3>
                    <h3 v-else>
                      รอการประกาศรายชื่อ
                    </h3>
                  </v-col>
                  <v-col class="hidden-sm-and-down" md="2">
                    <v-icon
                      size="64"
                      v-if="
                        sedirector_apps.sedirector_app_pvc_approve === 'pass' &&
                          sedirector_apps.sedirector_app_pvc_approve_ipa ===
                            'notice'
                      "
                      color="success"
                    >
                      mdi-account-check
                    </v-icon>
                    <v-icon size="64" v-else>
                      mdi-account-remove
                    </v-icon>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>

          <v-timeline-item color="amber lighten-1" fill-dot left small v-if="sedirector_apps.sedirector_app_ptype==='3'">
            <v-card>
              <v-card-title class="amber lighten-1 justify-end">
                <div class=" mr-4 white--text font-weight-light">
                  ผู้สมัคร สอบ ภาค ก
                </div>
                <v-icon dark size="42">
                  mdi-message-processing
                </v-icon>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col class="hidden-sm-and-down" md="2">
                    <!-- <v-icon
                      size="64"
                      color="success"
                      v-if="
                        sedirector_apps.sedirector_app_pvc_approve === 'pass' &&
                          sedirector_apps.sedirector_app_pvcscore_A_ipa ===
                            'notice'
                      "
                    >
                      mdi-file-check
                    </v-icon>
                    <v-icon size="64" v-else>
                      mdi-file
                    </v-icon> -->
                  </v-col>
                  <v-col cols="12" md="10">
                    ความรู้และความสามารถในการบริหารงานในหน้าที่ สอบข้อเขียนแบบปรนัย
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>


          <v-timeline-item color="amber lighten-1" fill-dot left small v-if="sedirector_apps.sedirector_app_ptype==='1'">
            <v-card>
              <v-card-title class="amber lighten-1 justify-end">
                <div class=" mr-4 white--text font-weight-light">
                  วิทยาลัยต้นสังกัด
                </div>
                <v-icon dark size="42">
                  mdi-message-processing
                </v-icon>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col class="hidden-sm-and-down" md="2">
                    <v-icon
                      size="64"
                      color="success"
                      v-if="
                        sedirector_apps.sedirector_app_pvc_approve === 'pass' &&
                          sedirector_apps.sedirector_app_pvcscore_A_ipa ===
                            'notice'
                      "
                    >
                      mdi-file-check
                    </v-icon>
                    <v-icon size="64" v-else>
                      mdi-file
                    </v-icon>
                  </v-col>
                  <v-col cols="12" md="10">
                    รายงานผลการประเมิน ภาค ก. จาก กรรมการ ระดับสถานศึกษา
                    ต้นสังกัด
                    <h3
                      v-if="sedirector_apps.sedirector_app_pvcscore_A >= 60"
                      class="success--text"
                    >
                      ผ่านคุณสมบัติ ภาค ก.
                    </h3>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>

          <v-timeline-item color="amber lighten-1" fill-dot left small>
            <v-card>
              <v-card-title class="amber lighten-1 justify-end">
                <div class=" mr-4 white--text font-weight-light">
                  สำนักงานอาชีวศึกษาจังหวัด
                </div>
                <v-icon dark size="42">
                  mdi-message-processing
                </v-icon>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col class="hidden-sm-and-down" md="2">
                    <v-icon
                      size="64"
                      color="success"
                      v-if="checksedirector_app_score_B === 'true'"
                    >
                      mdi-file-check
                    </v-icon>
                  </v-col>
                  <v-col cols="12" md="10">
                    รายงานผลการประเมิน ภาค ข. จาก กรรมการ สำนักงานอาชีวศึกษาจังหวัด
                    <h3
                      class="success--text"
                      v-if="checksedirector_app_score_B === 'true'"
                    >
                      รายงานผลการประเมิน
                    </h3>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>

          <v-timeline-item color="cyan lighten-1" fill-dot right>
            <v-card>
              <v-card-title class="cyan lighten-1">
                <v-icon class="mr-4" dark size="42">
                  mdi-domain
                </v-icon>
                <h2 class=" white--text font-weight-light">
                  ส่วนกลาง สอศ.
                </h2>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col cols="12" md="10">
                    สัมภาษณ์ ภาค ค
                    <h3
                      class="success--text"
                      v-if="
                        sedirector_apps.sedirector_app_ipastatus === 'guarantee'
                      "
                    >
                      รับรองผลการประเมิน
                    </h3>
                  </v-col>
                  <v-col class="hidden-sm-and-down" md="2">
                    <v-icon
                      color="success"
                      size="64"
                      v-if="
                        sedirector_apps.sedirector_app_ipastatus === 'guarantee'
                      "
                    >
                      mdi-account-settings
                    </v-icon>
                    <v-icon size="64" v-else>
                      mdi-account-settings
                    </v-icon>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>

          <v-timeline-item color="cyan lighten-1" fill-dot right>
            <v-card>
              <v-card-title class="cyan lighten-1">
                <v-icon class="mr-4" dark size="42">
                  mdi-domain
                </v-icon>
                <h2 class=" white--text font-weight-light">
                  ส่วนกลาง สอศ.
                </h2>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col cols="12" md="10">
                    ประกาศรายชื่อผู้ผ่านการคัดเลือก
                    <h3
                      class="success--text"
                      v-if="
                        sedirector_apps.sedirector_app_ipanotice === 'Accept'
                      "
                    >
                      ลำดับที่ :
                      {{ sedirector_apps.sedirector_app_idnoaccount }}
                    </h3>
                  </v-col>
                  <v-col class="hidden-sm-and-down" md="2">
                    <v-icon
                      color="success"
                      size="64"
                      v-if="
                        sedirector_apps.sedirector_app_ipanotice === 'Accept'
                      "
                    >
                      mdi-account-settings
                    </v-icon>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>

          <v-timeline-item color="red lighten-1" fill-dot left small>
            <v-card>
              <v-card-title class="red lighten-1 justify-end">
                <h2 class=" mr-4 white--text font-weight-light">
                  ส่วนกลาง สอศ.
                </h2>
                <v-icon dark size="42">
                  mdi-domain
                </v-icon>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col class="hidden-sm-and-down" md="2">
                    <v-icon size="64">
                      mdi-server-network
                    </v-icon>
                  </v-col>
                  <v-col cols="12" md="10">
                    รายงานตัวเลือกสถานศึกษา
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>

          <v-timeline-item color="green lighten-1" fill-dot right>
            <v-card>
              <v-card-title class="green lighten-1">
                <v-icon class="mr-4" dark size="42">
                  mdi-domain
                </v-icon>
                <h2 class=" white--text font-weight-light">
                  ส่วนกลาง สอศ.
                </h2>
              </v-card-title>
              <v-container>
                <v-row>
                  <v-col cols="12" md="10">
                    รายงานตัว ณ สถานศึกษา
                  </v-col>
                  <v-col class="hidden-sm-and-down" md="2">
                    <v-icon size="64">
                      mdi-account-star
                    </v-icon>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </div>
    </v-card>

    <!-- V-model pdfHrvecRegisDirdialog -->
    <v-layout row justify-center>
      <v-dialog v-model="pdfHrvecRegisDirdialog" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="'/HrvecRegisDir/' + pdffiles"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "HRvecSedirectorAppTimeline",

  data() {
    return {
      ApiKey: "HRvec2021",
      sedirector_apps: [],
      period_sedirectors: [],
      checksedirector_app_idrefs: [],
      checksedirector_app_score_B: [],
      pdfHrvecRegisDirdialog: false,
      pdffiles: ""
    };
  },

  async mounted() {
    await this.period_sedirectorQuery();
    await this.sedirector_appQuery();
  },

  methods: {
    async pdfHrvecRegisDir(pdffile) {
      this.pdffiles = "";
      this.pdffiles = pdffile;    
      this.pdfHrvecRegisDirdialog = true;
    },

    async period_sedirectorQuery() {
      let result_period;
      result_period = await this.$http.post("period_sedirector.php", {
        ApiKey: this.ApiKey,
        period_sedirector_process: "1",
        period_sedirector_id: "201"
      });
      this.period_sedirectors = result_period.data;
    },
    async sedirector_appQuery() {
      let useression = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_year: this.period_sedirectors.period_sedirector_year,
        sedirector_app_time: this.period_sedirectors.period_sedirector_times,
        sedirector_app_idcard: useression.id_card,     
      });
      this.sedirector_apps = result.data;  
      let checkdata = String(this.sedirector_apps.sedirector_app_idref);
      if (checkdata.length > 10) {
        this.checksedirector_app_idrefs = "true";
      } else {
        this.checksedirector_app_idrefs = "false";
      }

      let checkdata_scoreb = String(
        this.sedirector_apps.sedirector_app_pvcscore_B_file
      );
      if (checkdata_scoreb.length > 10) {
        this.checksedirector_app_score_B = "true";
      } else {
        this.checksedirector_app_score_B = "false";
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
